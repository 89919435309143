import React, { FC, PropsWithChildren } from "react";

const IntroPage: FC<PropsWithChildren> = ({ children }) => (
  <div className=" flex flex-col h-full justify-center items-center p-4">
    <div className="flex flex-col h-full justify-center items-center py-4 sm:w-[400px]">
      {children}
    </div>
  </div>
);

export default IntroPage;