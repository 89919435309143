import React, {FC, PropsWithChildren, useEffect, useState} from "react";
import axios from "axios";
import { Store, useStore } from "../store";
import {API_URL, ENVIRONMENT} from "../utils/consts";
import { getOrInitializeUser } from "../utils/user-manager";
import '../i18n/config';
import Logo from "../assets/logo.svg";

const Splash: FC<PropsWithChildren> = ({ children }) => {
  let demo = window.location.search.includes("demo=true")

  const setAppData = useStore((state: Store) => state.setAppData)
  const setUserData = useStore((state: Store) => state.setUserData)
  const setAppDataLoaded = useStore((state: Store) => state.setAppDataLoaded)
  const userData = useStore((state: Store) => state.userData)
  const appData = useStore((state: Store) => state.appData)
  const { id: userId } = userData;

  const [waited, setWaited] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const counting = () => {
      if(counter > 0 && !waited) {
        setWaited(true);
      }
      setCounter(prev => (prev + 1) % 4);
    }
    const intervalId = setInterval(counting, 1500)

    return () => clearInterval(intervalId);
  }, [waited, counter])

  useEffect(() => {
    if (ENVIRONMENT === 'development' || demo) {
      if (!userId) {
        getOrInitializeUser().then(user => setUserData({ ...user, loaded: true }));
      }

      setAppDataLoaded(false);

      axios.get(`${API_URL}/data`).then(response => {
        setAppData({ ...response.data, loaded: true })
      }).catch(e => {
        console.log(e)
        setAppData({
          totalUsers: 0,
          activeUsers: 0,
          totalCountries: 0,
          activeUsersPerCountry: [],
          intention: { id: 0, text: 'There is no intention for Today, feel free to come up with your own.' },
          loaded: true,
        })
      })
    }
  }, [userId, demo, setAppDataLoaded, setAppData, setUserData])

  if(waited && userData.loaded && appData.loaded) {
    return <>{children}</>
  }

  return(
    <div className="page justify-center text-white">
      {/*<p className="text-5xl mb-4 sacramento">Oraya</p>*/}
      <img className="mb-4 text-white h-[72px]" src={Logo} alt="Oraya logo" />
      <p className="text-xs text-[rgba(255,255,255,0.6)]">
        Unite in&nbsp;
        <span className={counter === 1 ? "text-white" : ""}>Reflection</span>
        ,&nbsp;
        <span className={counter === 2 ? "text-white" : ""}>Purpose</span>
        ,&nbsp;and&nbsp;
        <span className={counter === 3 ? "text-white" : ""}>Hope</span>
      </p>
      <p className="font-light text-sm mt-16 p-2 text-[rgba(255,255,255,0.6)]">
        COMING SOON...
      </p>
    </div>
  )
}

export default Splash;