import React, {FC} from "react";
import { useTranslation } from "react-i18next";

const Stats: FC<{ appData: any }> = ({ appData }) => {
  const { t } = useTranslation();

  return (
    <div className="rounded-xl text-white p-2 w-full flex flex-row justify-around">
      <div>
        <p className="text-center text-2xl pb-1">
          {appData.activeUsers}
        </p>
        <p className="text-center secondary uppercase text-xs">
          {t('stats.connected')}
          <br/>
          {t('stats.now')}
        </p>
      </div>
      <div>
        <p className="text-center text-2xl pb-1">
          {appData.totalUsers}
        </p>
        <p className="text-center secondary uppercase text-xs">
          {t('stats.connected')}
          <br/>
          {t('stats.today')}
        </p>
      </div>
      <div>
        <p className="text-center text-2xl pb-1">
          {appData.totalCountries}
        </p>
        <p className="text-center secondary uppercase text-xs">
          {t('stats.countries')}
          <br/>
          {t('stats.today')}
        </p>
      </div>
    </div>
  )
}

export default Stats;