import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from './en/translation.json';
import translationPl from './pl/translation.json';
import translationEs from './es/translation.json';
import translationFr from './fr/translation.json';

// declare module "i18next" {
//   interface CustomTypeOptions {
//     defaultNS: "translation";
//     // custom resources type
//     resources: {
//       translation: typeof translationEn;
//     };
//   }
// }

const initI18n = async () => {
  // TODO
  // let savedLanguage = await SecureStore.getItemAsync("language");
  // const { languageCode } = getLocales()[0];

  let savedLanguage;
  let languageCode = 'en'

  if (!savedLanguage) {
    savedLanguage = languageCode;
  }

  await i18next.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    resources: {
      en: { translation: translationEn },
      pl: { translation: translationPl },
      es: { translation: translationEs },
      fr: { translation: translationFr },
    },
    lng: savedLanguage,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    }
  });
};

initI18n().catch(e => console.log("error-------", e));

export default i18next;