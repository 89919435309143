import React, { useCallback, useEffect, useRef } from "react";

const Animation = () => {
  const amount = 100;

  return (
    <div className="relative w-[200px] h-[200px]">
      {Array.from({ length: amount }, (_, index) => (
        <Particle key={index} />
      ))}
    </div>
  );
}

const Particle = () => {
  const ref = useRef<HTMLDivElement | null>(null);


  const setStyles = useCallback((el: HTMLDivElement) => {
      const alpha = Math.random() * 0.8 + 0.1;
      const size = Math.random() * 8 + 2;

      const t = 2 * Math.PI * Math.random();
      const r = Math.sqrt(Math.random());
      const cos = Math.cos(t);
      const sin = Math.sin(t);
      const x = r * cos;
      const y = r * sin;
      const rMultiplier = 200;

      el.style.setProperty("width", `${size}px`)
      el.style.setProperty("height", `${size}px`)
      el.style.setProperty("--x-target", `${rMultiplier * x}px`)
      el.style.setProperty("--y-target", `${rMultiplier * y}px`)
      el.style.setProperty("background-color", `rgba(255, 255, 255, ${alpha})`)
      el.style.setProperty("box-shadow", `rgba(255, 255, 255, ${alpha}) 0px 2px 8px 0px`)
    },
    []
  )

  useEffect(() => {
    if (ref.current) {
      const el = ref.current!;

      setStyles(el)

      const handleAnimationIteration = (_event: AnimationEvent) => {
        setStyles(el)
      };

      el.addEventListener("animationiteration", handleAnimationIteration);

      return () => { el.removeEventListener("animationiteration", handleAnimationIteration) }
    }
  }, [ref, setStyles])

  return <div ref={ref} className="particle" />;
}

export default Animation;