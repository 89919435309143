import React, { FC, useEffect, useMemo, useState, } from "react";
import axios from "axios";
import { isNull } from "lodash";
import { useTranslation } from 'react-i18next'
import { API_URL } from "../../utils/consts";
import {Store, useStore} from "../../store";
import AudioControls from "./audioControls";
import Animation from "./animation";

const Meditation: FC= () => {
  const { t } = useTranslation();

  const goToPage = useStore((state: Store) => state.goToPage)
  const appData = useStore((state: Store) => state.appData)
  const userData = useStore((state: Store) => state.userData)
  const setAppData = useStore((state: Store) => state.setAppData)
  const setUserData = useStore((state: Store) => state.setUserData)

  const [loaded, setLoaded] = useState(false);

  const intention = useMemo(
    () => userData.userIntentions.find(ui => isNull(ui.endTime)),
    [userData.userIntentions]
  );

  useEffect(() => {
    setLoaded(true);
  }, []);

  const finish = () => {
    if (!intention || userData.id === "null" ) {
      setUserData({ ...userData, userIntentions: [{ ...userData.userIntentions[0], endTime: new Date() }] })
      goToPage("summary", 0)
      return
    }

    axios.patch(`${API_URL}/user_intentions/${intention.id}/finish`, { user_id: userData.id  })
      .then(response => {
        setAppData({ ...appData, activeUsers: appData.activeUsers - 1 })
        const finishedIntention = response.data;
        const intentionIndex = userData.userIntentions.findIndex(i => i.id === finishedIntention.id)
        const userIntentions = [userData.userIntentions.slice(0, intentionIndex), finishedIntention, userData.userIntentions.slice(intentionIndex + 1)];
        setUserData({ ...userData, userIntentions })
        goToPage("summary", finishedIntention.id);
      })
      .catch(err => console.log(err));
  }

  return(
    <div className="page h-full justify-center">
      <div className="flex flex-col justify-center items-center w-full">
        {appData.intention && (
          <>
            <p className="text-sm mt-12 uppercase secondary">
              {t('home.intentionHeader')}
            </p>

            <p className="my-12 light text-2xl" style={{ textShadow: "0px 0px 10px white"}}>
              {appData.intention.text}
            </p>
          </>
        )}
      </div>

      <div
        className="flex flex-col justify-center items-center h-0 transition-all duration-[2s]"
        style={{
          flexGrow: loaded ? 1 : 0.00001,
          opacity: loaded ? '100%' : '0%',
        }}
      >
        <div className="w-full z-10">
          <AudioControls />
        </div>

        <div className="flex flex-1 justify-center items-center">
          <Animation />
        </div>
      </div>

      <button onClick={finish} className="btn primary mb-4 z-10">
        {t('meditation.buttonLabel')}
      </button>
    </div>
  );
}

export default Meditation;