import { create } from 'zustand'
import axios from "axios";
import { API_URL } from "./utils/consts";

type PageName = undefined | "intro" | "home" | "meditation" | "summary" | "settings";
export type Page = {
  name: PageName;
  userIntentionId?: number;
}

type Intention = {
  id: number;
  text: string;
}

type AppData = {
  totalUsers: number;
  activeUsers: number;
  totalCountries: number;
  activeUsersPerCountry: { country: string, activeUsers: number}[];
  intention: Intention;
  loaded: boolean;
}

export type UserIntention = {
  id: number;
  intentionId: number;
  userId: string;
  startTime: Date;
  endTime?: Date;
  autoFinished: boolean;
}

type UserData = {
  id: string | undefined;
  name: string | undefined;
  email: string | undefined;
  country: string | undefined;
  city: string | undefined;
  language: string | undefined;
  introCompleted: boolean;
  userIntentions: UserIntention[];
  loaded: boolean;
}

type State = {
  page: Page;
  userData: UserData;
  appData: AppData;
}

type Action = {
  setUserData: (userData: State['userData']) => void;
  setAppData: (data: State['appData']) => void;
  fetchAppData: () => void;
  goToPage: (name: PageName, userIntentionId?: number) => void;
  setAppDataLoaded: (loaded: boolean) => void;
  setUserDataLoaded: (loaded: boolean) => void;
}

export type Store = State & Action;

export const useStore = create<Store>((set) => ({
  page: { name: undefined },
  userData: {
    id: undefined,
    name: undefined,
    email: undefined,
    country: undefined,
    city: undefined,
    language: undefined,
    introCompleted: false,
    userIntentions: [],
    loaded: false,
  },
  appData: {
    totalUsers: 0,
    activeUsers: 0,
    totalCountries: 0,
    activeUsersPerCountry: [],
    intention: { id: 0, text: '' },
    loaded: false,
  },
  setUserData: (userData) => set(() => ({ userData })),
  setAppData: (appData) => set(() => ({ appData } )),
  fetchAppData: () => axios.get(`${API_URL}/data`)
    .then(response => { set({ appData: { ...response.data, loaded: true }}) })
    .catch((err) => console.log(err)),
  goToPage: (name, userIntentionId) => set(() => ({ page: { name, userIntentionId } })),
  setUserDataLoaded: (loaded) => set(state => ({ userData: { ...state.userData, loaded } })),
  setAppDataLoaded: (loaded) => set(state => ({ appData: { ...state.appData, loaded } })),
}))