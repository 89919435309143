import React, {FC, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { WiStars } from "react-icons/wi";
import { Store, useStore } from "../../store";
import Language from "../../components/Language";
import IntroPage from "./IntroPage";
import Country from "../../components/Country";


const Step1: FC<{ onNext: MouseEventHandler<HTMLButtonElement>}> = ({ onNext }) => {
  const { t } = useTranslation()

  const userData = useStore((state: Store) => state.userData)
  const { country } = userData;

  return(
    <IntroPage>
      <div className="flex justify-center">
        <Language />
      </div>

      <div className="flex flex-1 flex-col w-full content-center justify-center">
        <p className="text-center mb-8 sacramento">
          {t('intro.step1.title')}
        </p>

        <p className="text-center mb-8">
          {t('intro.step1.subtitle')}
        </p>

        <div className="flex flex-col items-center justify-center">
          <Country />
        </div>
      </div>

      <div className="flex justify-center">
        <button onClick={onNext} disabled={!country} className="btn secondary">
          {t('intro.step1.buttonLabel')}
        </button>
      </div>
    </IntroPage>
  )
};

export default Step1;