import React, {FC, MouseEventHandler, useState} from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

const Intro: FC = () => {
  const [step, setStep] = useState(0)

  const onNextClick: MouseEventHandler<HTMLButtonElement> = () => setStep(prev => prev + 1);

  return(
    <>
      {step === 0 && <Step1 onNext={onNextClick} />}
      {step === 1 && <Step2 onNext={onNextClick} />}
      {step === 2 && <Step3 onNext={onNextClick} />}
    </>
  )
};

export default Intro;