import axios from "axios";
import { set, get } from 'idb-keyval';
import { UserIntention } from "../store";
import { API_URL } from "./consts";

const nullUser = {
  id: "null",
  name: "",
  email: "",
  country: "",
  city: "",
  language: "english",
  introCompleted: false,
  userIntentions: [],
  loaded: true,
}

export const getOrInitializeUser = async () => {
  const userIdKey = "oraya.userId";
  let userId  = await get(userIdKey);

  if (userId) {
    const response = await axios.get(`${API_URL}/users/${userId}`).catch(error => console.log("Sign up error: ", error));
    if (response && response.status === 200) {
      return response.data;
    } else {
      return nullUser;
    }
  }

  try {
    const { data } = await axios.post(`${API_URL}/users/signup`);
    userId = data.id
    await set(userIdKey, userId);
    return data
  } catch (error) {
    console.log("Sign up error: ", error)
    return nullUser;
  }
}