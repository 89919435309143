import React, { FC, MouseEventHandler } from "react";
import IntroPage from "./IntroPage";
import { useTranslation } from "react-i18next";
import Infinity from "../../components/shapes/Infinity"

const Step1: FC<{ onNext: MouseEventHandler<HTMLButtonElement>}> = ({ onNext }) => {
  const { t } = useTranslation()

  return(
    <IntroPage>
      <div className="flex flex-1 flex-col w-full content-center justify-center">
        <p className="text-center mb-8 sacramento">
          {t('intro.step2.title')}
        </p>

        <Infinity height="100px" className="pathShadow mb-8" />

        <p className="text-center mb-8">
          {t('intro.step2.subtitle')}
        </p>
        <p className="text-center mb-16">
          {t('intro.step2.subtitle2')}
        </p>
      </div>

      <div className="flex justify-center">
        <button onClick={onNext} className="btn secondary">
          {t('intro.step2.buttonLabel')}
        </button>
      </div>
    </IntroPage>
  )
};

export default Step1;