import React, {useEffect, useState} from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next'
import { API_URL } from "../../utils/consts";
import { Store, useStore } from "../../store";
import Map from "../../stats/Map";
import Settings from "../../components/Settings";
import Stats from "../../stats";

const poolingInterval = 10000;

const Home = () => {
  const { t } = useTranslation();

  const goToPage = useStore((state: Store) => state.goToPage)
  const appData = useStore((state: Store) => state.appData)
  const userData = useStore((state: Store) => state.userData)
  const setAppData = useStore((state: Store) => state.setAppData)
  const setUserData = useStore((state: Store) => state.setUserData)
  const fetchAppData = useStore((state: Store) => state.fetchAppData)

  const [mapSize, setMapSize] = useState({ width: 0, height: 0 })

  useEffect(() => {
    if (userData.id !== "null") {
      fetchAppData();
      const intervalId = setInterval(fetchAppData, poolingInterval);

      return () => clearInterval(intervalId);
    }
  }, [userData.id, fetchAppData])

  useEffect(() => {
    const updateMapWidth = () => {
      let mapWidth = Math.min(window.outerWidth, 700);
      const mapHeight = Math.min(window.outerHeight / 2, mapWidth * 9 / 16);
      if (mapWidth > mapHeight * 16 /9) {
        mapWidth = mapHeight * 16 /9
      }
      setMapSize({ width: mapWidth - 24, height: mapHeight - 24 });
    }
    window.addEventListener('resize', updateMapWidth);
    updateMapWidth();

    return () => window.removeEventListener('resize', updateMapWidth);
  }, []);

  const join = () => {
    if (userData.id === "null") {
      setUserData({ ...userData, userIntentions: [
        {
          id: 0,
          intentionId: 0,
          userId: "null",
          startTime: new Date(),
          endTime: undefined,
          autoFinished: false,
        }
      ] })
      goToPage("meditation");
      return;
    }

    axios.post(`${API_URL}/user_intentions/start`, { user_id: userData.id, intention_id: appData.intention.id })
      .then(response => {
        setAppData({...appData, activeUsers: appData.activeUsers + 1})
        setUserData({ ...userData, userIntentions: [...userData.userIntentions, response.data] })
        fetchAppData();
        goToPage("meditation");
      })
      .catch(err => console.log(err));
  }

  return(
    <div className="page h-full">
      <div className="w-full flex-1 flex flex-col justify-center items-center">
        <Settings />

        <div>
          {appData.intention && (
            <>
              <p className="text-sm uppercase secondary">
                {t('home.intentionHeader')}
              </p>

              <p className="my-12 light text-2xl" style={{ textShadow: "0px 0px 10px white"}}>
                {appData.intention.text}
              </p>

              <button onClick={join} className="btn primary">
                {t('home.buttonLabel')}
              </button>
            </>
          )}
        </div>
      </div>

      {userData.id !== "null" && (
        <div className="w-full mt-8flex flex-col justify-center items-center">
          <Map
            userCountryCode={userData.country}
            width={mapSize.width}
            height={mapSize.height}
          />
          <Stats appData={appData} />
        </div>
      )}
    </div>
  );
}

export default Home;